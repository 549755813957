<template>
    <div class="container">
        <div class="row no-gutter" v-if="showForm">
            <div class="col p-0">
                <div class="claim-form mt-4">
                    <div class="claim-form__header">
                        <div>
                            <h2 class="claim-form__title">Request for License details</h2>
                            <p class="claim-form__subtitle claim-form__subtitle--light">In order to process your replacement bike hire, fill in the form and submit it.</p>
                        </div>

                        <div class="claim-form__icon">
                            <img :src="data.imgurl" alt="" />
                        </div>
                    </div>

                    <div class="claim-form__body">
                        <ul class="claim-form__ul" type="none">
                            <li class="claim-form__section section">
                                <h2 class="claim-form__subtitle">Your Details</h2>
                            </li>
                            <li class="claim-form__section section">
                                <FormulateInput
                                    type="text"
                                    name="claim-number"
                                    label="Claim Number"
                                    v-model="data.claimNumber" />
                            </li>
                            <li class="claim-form__section section">
                                <div class="row no-gutter">
                                    <div class="col col-md-6">
                                        <FormulateInput
                                            type="text"
                                            class="w-100"
                                            label="Your Name"
                                            name="first-name"
                                            help="Full Name"
                                            v-model="data.fullName" />
                                    </div>
                                </div>
                            </li>
                            <li class="claim-form__section section">
                                <h2 class="claim-form__subtitle">Your License Details</h2>
                                <p>Enter your details in this section</p>
                            </li>
                            <li class="claim-form__section section">
                                <FormulateInput
                                    type="text"
                                    class="w-100"
                                    validation="required"
                                    label="Drivers License number"
                                    name="license-number"
                                    v-model="data.license.number" />
                            </li>
                            <li class="claim-form__section section">
                                <FormulateInput
                                    v-model="data.license.country"
                                    type="radio"
                                    label="Country License registered to"
                                    name="license-country"
                                    validation="required"
                                    :options="{ Australia: 'Australia', Another: 'Another Country' }" />
                            </li>
                            <template v-if="australiaLicense">
                            <li class="claim-form__section section">
                                <FormulateInput
                                    label="State in which the license was obtained"
                                    type="select"
                                    name="license-state"
                                    validation="required"
                                    :options="stateOptions"
                                    v-model="data.license.state"
                                />
                            </li>
                            </template>

                            <template v-if="otherCountryLicense">
                            <li class="claim-form__section section">
                                <FormulateInput
                                    type="text"
                                    label="Country in which the license was obtained"
                                    name="license-other"
                                    validation="required"
                                    v-model="data.license.otherCountryName" />
                            </li>
                            </template>

                            <template v-if="australiaLicense || otherCountryLicense">
                            <li class="claim-form__section section">
                                <FormulateInput
                                    v-model="files.licenseFront"
                                    type="image"
                                    class="w-100"
                                    name="license-front"
                                    label="Upload a copy or Click to take a photo of your license (Front)"
                                    help="Take a photo of your license from the Front only"
                                    validation="mime:image/jpeg,image/png,image/gif" />
                            </li>
                            <li class="claim-form__section section">
                                <FormulateInput
                                    v-model="files.licenseBack"
                                    type="image"
                                    class="w-100"
                                    name="license-back"
                                    label="Upload a copy or Click to take a photo of your license (Back)"
                                    help="Take a photo of your license from the Back only"
                                    validation="mime:image/jpeg,image/png,image/gif" />
                            </li>
                            </template>

                            <template v-if="otherCountryLicense">
                            <li class="claim-form__section section">
                                <FormulateInput
                                    v-model="files.passportPhoto"
                                    type="image"
                                    class="w-100"
                                    name="passport"
                                    label="Upload a copy of your Passport photo"
                                    help="Take a photo of your Passport with your picture displayed"
                                    validation="mime:image/jpeg,image/png,image/gif" />
                            </li>
                            <li class="claim-form__section section">
                                <FormulateInput
                                    v-model="files.passportAddress"
                                    type="image"
                                    class="w-100"
                                    name="resident-doc"
                                    label="Upload a copy of a document with your address"
                                    help="Provide a photo of a Phone, Electricity, Water bill or Bank statement with you residential address"
                                    validation="mime:image/jpeg,image/png,image/gif" />
                            </li>
                            </template>
                            <li class="claim-form__section section d-flex justify-content-center">
                                <FormulateInput
                                    type="submit" @click="sendDetails" />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
      <div style="position: fixed; left: 50%; top: 50%; transform: translate(-50%, -50%);" v-if="showSuccess">
        <h2>Data has been sent successfully</h2>
      </div>
      <div style="position: fixed; left: 50%; top: 50%; transform: translate(-50%, -50%);" v-if="showError">
        <h2>FORM NOT FOUND</h2>
      </div>
    </div>

</template>

<script>
    import Axios from "axios";

    export default {
        name: 'request-license',
        data() {
            return {
                showForm: true,
                showSuccess: false,
                showError: false,
                hash: '',
                files: {
                    licenseFront: null,
                    licenseBack: null,
                    passportPhoto: null,
                    passportAddress: null,
                },
                data: {
                    test: null,
                    claimNumber: '',
                    firstName: '',
                    lastName: '',
                    fullName: '',
                    license: {
                        number: '',
                        country: '',
                        otherCountryName: '', // if other country selected
                        state: '',
                    }
                },
                imgurl: 'https://dev.pay.yourrighttoride.com.au/img/logo.png',
                publishableKey: 'pk_test_TYooMQauvdEDq54NiTphI7jx',
                stateOptions: {
                    new_south_wales: 'New South Wales',
                    victoria: 'Victoria',
                    queensland: 'Queensland',
                    western_australia: 'Wwesten Australia',
                    south_australia: 'South Australia',
                    Tasmania: 'Tasmania',
                    northern_teritory: 'Northern Teritory'
                }
            };
        },
        computed: {
            australiaLicense() {
                return this.data.license.country == 'Australia';
            },
            otherCountryLicense() {
                return this.data.license.country == 'Another';
            },
        },
      created() {
        this.hash = this.$route.params.code;
      },
      mounted(){
          this.loadClaimData();
        },
        methods: {
          sendDetails(){
            let data = {
              claimCode: this.hash,
              licenseNumber: this.data.license.number,
              country: this.data.license.country,
              state: this.data.license.state,
              anotherCountry: this.data.license.otherCountryName,
            };

            let fd = new FormData();
            fd.append('data', JSON.stringify(data));
            fd.append('license_photo_front', this.files.licenseFront && this.files.licenseFront.input.files ? this.files.licenseFront.input.files[0] : null)
            fd.append('license_photo_back', this.files.licenseBack && this.files.licenseBack.input.files ? this.files.licenseBack.input.files[0] : null)
            fd.append('passport_photo', this.files.passportPhoto && this.files.passportPhoto.input.files ? this.files.passportPhoto.input.files[0] : null)
            fd.append('passport_address_photo', this.files.passportAddress && this.files.passportAddress.input.files ? this.files.passportAddress.input.files[0] : null)

            Axios.post('/efr/license-form-data', fd, { headers: { 'Content-Type': 'multipart/form-data' }})
              .then(res => {
                if(res && res.data && res.data._status){
                  this.$toast.success('SAVED!');
                  this.showForm = false;
                  this.showSuccess = true;
                } else if(res.data._errors) {
                  res.data._errors.forEach(e => this.$toast.error(e));
                }
              })
              .catch(e => console.warn(e));
          },
          loadClaimData(){
            Axios.get('/efr/license-form-data/' + this.hash)
              .then(res => {
                if(res && res.data && res.data._status){
                  this.data.claimNumber = res.data.data.claimNumber;
                  this.data.firstName = res.data.data.firstName;
                  this.data.lastName = res.data.data.lastName;
                  this.data.fullName = this.data.firstName + ' ' + this.data.lastName;
                } else {
                  this.showForm = false;
                  this.showError = true;
                }
              })
              .catch(e => console.warn(e));
          }
        },
    }
</script>
